import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'

import { registerUser, registerUserError, clearAlerts } from '../actions'
import { selectOrganisations } from '../reducers/entities/organisations'
import DataManagementAlert from './DataManagementAlert'

export function AddUser() {
    const dispatch = useDispatch()
    const organisationInput = useRef(null)
    const nameInput = useRef(null)
    const emailInput = useRef(null)
    const expiryDateInput = useRef(null)
    const adminInput = useRef(null)
    const passwordInput = useRef(null)
    const confirmPasswordInput = useRef(null)
    const organisations = useSelector(selectOrganisations)
    const { status, message } = useSelector(state => state.dataManagement.register)

    useEffect(() => {
        dispatch(clearAlerts())
        const TODAY = new Date()
        const NEXT_YEAR = new Date(TODAY.setFullYear(TODAY.getFullYear() + 1))
        expiryDateInput.current.value = NEXT_YEAR.toISOString().split('T')[0]
    }, [dispatch])

    function onSubmitHandler(e) {
        e.preventDefault()
        const organisation_id = organisationInput.current.value
        const user_name = nameInput.current.value
        const user_email = emailInput.current.value
        const expiry_date = expiryDateInput.current.value
        const is_admin = adminInput.current.checked
        const user_password = passwordInput.current.value
        const confirm_password = confirmPasswordInput.current.value

        if (organisation_id && user_name && user_email && user_password && confirm_password) {
            if (user_password === confirm_password) {
                const data = {
                    user_name,
                    user_email,
                    organisation_id,
                    user_password,
                    is_admin,
                    expiry_date,
                }
                dispatch(registerUser(data))
            } else {
                dispatch(registerUserError('Password and confirmation must match'))
            }
        } else {
            dispatch(registerUserError(
                'All compulsory fields indicated with * must be filled in'
            ))
        }
    }

    return (
        <>
            <h3>Add User</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="organisation" md={3}>
                        Organisation
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisation"
                            id="organisation"
                            type="select"
                            innerRef={organisationInput}
                        >
                            { organisations.map(organisation => (
                                <option
                                    key={organisation.organisation_id}
                                    value={organisation.organisation_id}
                                >
                                    {organisation.organisation_name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_name" md={3}>
                        Name
                    </Label>
                    <Col md={6}>
                        <Input
                            name="user_name"
                            id="user_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_email" md={3}>
                        Email *
                    </Label>
                    <Col md={6}>
                        <Input
                            type="email"
                            name="user_email"
                            id="user_email"
                            innerRef={emailInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="expiry_date" md={3}>
                        Expiry Date
                    </Label>
                    <Col md={6}>
                        <Input
                            type="date"
                            name="expiry_date"
                            id="expiry_date"
                            innerRef={expiryDateInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_password" md={3}>
                        Password *
                    </Label>
                    <Col md={6}>
                        <Input
                            type="password"
                            name="user_password"
                            id="user_password"
                            innerRef={passwordInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="confirmPassword" md={3}>
                        Confirm Password *
                    </Label>
                    <Col md={6}>
                        <Input
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            innerRef={confirmPasswordInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="is_admin" md={3}>
                        Admin
                    </Label>
                    <Col md={6}>
                        <Input
                            type="checkbox"
                            name="is_admin"
                            id="is_admin"
                            innerRef={adminInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Create
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default AddUser
