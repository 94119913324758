import { map, fromPairs, sortBy} from 'lodash'
import {
    FETCH_ORGANISATIONS_RESPONSE,
    CREATE_ORGANISATION_RESPONSE,
    EDIT_ORGANISATION_RESPONSE,
    LOGOUT_USER,
} from '../../actions'

const organisations = (state={}, action) => {
    switch (action.type) {
        case CREATE_ORGANISATION_RESPONSE:
        case EDIT_ORGANISATION_RESPONSE:
            const { organisation_id } = action.response
            return { 
                ...state,
                [organisation_id]: action.response
            }
        case FETCH_ORGANISATIONS_RESPONSE:
            return {
                ...state,
                ...fromPairs(map(action.response, (organisation) => ([organisation.organisation_id, organisation])))
            }
        case LOGOUT_USER:
            return {}
        default:
            return state
    }
}

export default organisations

export const selectOrganisations = (state) => {
    const { organisations } = state.entities
    return sortBy(map(organisations, organisation => organisation), ["organisation_id"])
}

export const selectOrganisation = (state, id) => {
    const organisation = state.entities.organisations[id]
    return organisation
}