import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { fetchDashboard } from '../actions'
import { selectDashboard } from '../reducers/entities/dashboards'

export function Dashboard() {
    const dispatch = useDispatch()
    const { dashboard_id } = useParams()
    const dashboard = useSelector(state => selectDashboard(state, dashboard_id))
    const { dashboard_name, dashboard_url } = dashboard

    useEffect(() => {
        dispatch(fetchDashboard(dashboard_id))
    }, [dispatch, dashboard_id])

    return (
        <div>
            { dashboard_url &&
                <iframe
                    title={ dashboard_name }
                    width="100%"
                    height="850"
                    src={ dashboard_url }
                    style={{ border: 0 }}
                    allowFullScreen={true}
                />
            }
        </div>
    )
}

export default Dashboard