import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    Collapse,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { clearAlerts, logoutUser } from '../actions'
import { selectLoggedInUser } from '../reducers/entities/users'

export function Topbar() {
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(true)
    const { loggedIn, loggedInAdmin } = useSelector(state => state.auth)
    const loggedInUser = useSelector(selectLoggedInUser) || {}

    function logoutHandler() {
        dispatch(clearAlerts())
        dispatch(logoutUser())
        toggleNavbar()
    }

    function toggleNavbar() {
        setCollapsed(prev => !prev)
    }


    return (
        <Navbar key="nav" color="dark" dark>
            <NavbarBrand tag={Link} to="/">
                Eighty20 National Segmentation
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            to="/"
                            onClick={toggleNavbar}
                        >
                            Home
                        </NavLink>
                    </NavItem>
                </Nav>
                <hr />
                {loggedIn ? (
                    <Nav navbar>
                        <NavItem className="text-white">
                            {loggedInUser.name}{' '}
                            <i className="fas fa-user" />
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to="/account"
                                onClick={toggleNavbar}
                            >
                                My Account
                            </NavLink>
                        </NavItem>
                        <hr/>
                        {loggedInAdmin && (
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    to="/admin/users"
                                    onClick={toggleNavbar}
                                >
                                    Manage Users
                                </NavLink>
                                <NavLink
                                    tag={Link}
                                    to="/admin/organisations"
                                    onClick={toggleNavbar}
                                >
                                    Manage Organisations
                                </NavLink>
                                <NavLink
                                    tag={Link}
                                    to="/admin/dashboards"
                                    onClick={toggleNavbar}
                                >
                                    Manage Dashboards
                                </NavLink>
                            </NavItem>
                        )}
                        <hr/>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to="/login"
                                onClick={logoutHandler}
                            >
                                Logout
                            </NavLink>
                        </NavItem>
                    </Nav>
                ) : (
                    <Nav navbar>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to="/login"
                                onClick={toggleNavbar}
                            >
                                Login
                            </NavLink>
                        </NavItem>
                    </Nav>
                )}
            </Collapse>
        </Navbar>
    )
}


export default Topbar
