import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import reducer from '../reducers'

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)


const configureStore = () => {
    const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(thunk))
    )
        
    const persistor = persistStore(store)
    
    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('../reducers', () => {
                store.replaceReducer(reducer)
            })
        }
    }

    return { store, persistor }
}

export default configureStore
