import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardTitle, CardText, Button, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { fetchPublicDashboards, fetchOrganisationDashboards } from '../actions'
import { selectPublicDashboards, selectOrganisationDashboards } from '../reducers/entities/dashboards'

export function Home() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loggedInOrganisationId } = useSelector(state => state.auth)
    const publicDashboards = useSelector(selectPublicDashboards)
    const organisationDashboards = useSelector(state => selectOrganisationDashboards(state, loggedInOrganisationId))

    useEffect(() => {
        dispatch(fetchPublicDashboards())
        dispatch(fetchOrganisationDashboards(loggedInOrganisationId))
    }, [dispatch, loggedInOrganisationId])

    function openDashboard(dashboard_id) {
        history.push(`/dashboard/${dashboard_id}`)
    }

    return (
        <>
            <Row>
                <Col>
                    <h4>Public Dashboards</h4>
                    { publicDashboards.map((({dashboard_id, dashboard_name, dashboard_description}) => (
                        <Card body key={dashboard_id} className="mb-2">
                            <CardTitle tag="h5">{dashboard_name}</CardTitle>
                            <CardText>{dashboard_description}</CardText>
                            <Button
                                color="primary"
                                onClick={() => openDashboard(dashboard_id)}
                            >
                                Explore
                            </Button>
                        </Card>
                    )))}
                </Col>
                <Col>
                    <h4>Organisation Dashboards</h4>
                    { organisationDashboards.map((({dashboard_id, dashboard_name, dashboard_description}) => (
                        <Card body key={dashboard_id} className="mb-2">
                            <CardTitle tag="h5">{dashboard_name}</CardTitle>
                            <CardText>{dashboard_description}</CardText>
                            <Button
                                color="primary"
                                onClick={() => openDashboard(dashboard_id)}
                            >
                                Explore
                            </Button>
                        </Card>
                    )))}
                </Col>
            </Row>
        </>
    )
}

export default Home
