import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, Link, useRouteMatch, Redirect } from 'react-router-dom'
import {
    Nav,
    NavItem,
    NavLink,
    Col, Row
} from 'reactstrap'
import { fetchOrganisations } from '../actions'
import AddOrganisation from './AddOrganisation'
import EditOrganisation from './EditOrganisation'
import ListOrganisations from './ListOrganisations'

export function ManageOrganisations() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchOrganisations())
    }, [dispatch])
    const match = useRouteMatch()
    return (
        <>
            <Row>
                <Col md={2}>
                    <h3>Manage Organisations</h3>
                    <Nav pills vertical>
                        <NavItem active>
                            <NavLink tag={Link} to={`${match.path}/listOrganisations`}>
                                List Organisations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={`${match.path}/addOrganisation`}>
                                Add Organisation
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={`${match.path}/editOrganisation`}>
                                Edit Organisation
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md={10}>
                    <Switch>
                        <Route path={`${match.path}/listOrganisations`} component={ListOrganisations}/>
                        <Route path={`${match.path}/addOrganisation`} component={AddOrganisation}/>
                        <Route path={`${match.path}/editOrganisation/:organisation_id`} component={EditOrganisation}/>
                        <Redirect from={`${match.path}`} to={`${match.path}/listOrganisations`}/>
                    </Switch>
                </Col>
            </Row>
        </>
    )
}

export default ManageOrganisations
