import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { selectOrganisations } from '../reducers/entities/organisations'
import OrganisationTable from './OrganisationTable'

export function ListOrganisations() {
    const history = useHistory()
    const organisations = useSelector(selectOrganisations)
    const [selectedOrganisation, setSelectedOrganisation] = useState(null)

    const onSelect = (organisation_id) => {
        setSelectedOrganisation(organisation_id)
    }

    const onEdit = (e) => {
        e.preventDefault()
        history.push(`editOrganisation/${selectedOrganisation}`)
    }

    return (
        <>
            <h3>List Organisations</h3>
            <OrganisationTable organisations={organisations} onSelect={onSelect}/>
            <Button onClick={onEdit} disabled={selectedOrganisation === null} color="primary">Edit</Button>
        </>
    )
}

export default ListOrganisations
