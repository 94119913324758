import { combineReducers } from 'redux'

import {
    EDIT_OWN_USER_REQUEST,
    EDIT_OWN_USER_ERROR,
    EDIT_OWN_USER_RESPONSE,
    CHANGE_OWN_PASSWORD_REQUEST,
    CHANGE_OWN_PASSWORD_ERROR,
    CHANGE_OWN_PASSWORD_RESPONSE,
    CLEAR_ALERTS,
} from '../../actions'

const status = (state = '', action) => {
    switch (action.type) {
        case EDIT_OWN_USER_REQUEST:
        case CHANGE_OWN_PASSWORD_REQUEST:
            return 'request'
        case EDIT_OWN_USER_ERROR:
        case CHANGE_OWN_PASSWORD_ERROR:
            return 'error'
        case EDIT_OWN_USER_RESPONSE:
        case CHANGE_OWN_PASSWORD_RESPONSE:
            return 'response'
        case CLEAR_ALERTS:
            return ''
        default:
            return state
    }
}

const message = (state = '', action) => {
    switch (action.type) {
        case EDIT_OWN_USER_REQUEST:
        case EDIT_OWN_USER_ERROR:
        case EDIT_OWN_USER_RESPONSE:
        case CHANGE_OWN_PASSWORD_REQUEST:
        case CHANGE_OWN_PASSWORD_ERROR:
        case CHANGE_OWN_PASSWORD_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ''
        default:
            return state
    }
}

export default combineReducers({
    status,
    message,
})
