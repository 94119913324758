import { backendHost } from '../actions/backend'

/**
 * Convert the request status to an alert type
 * @param {string} status - The request status ("request", "response", "error") 
 */
export const statusToAlertType = (status) => {
    const alertTypes = {
        request: "info",
        response: "success",
        error: "danger"
    }
    return alertTypes[status]
}

/**
 * Make a backend API HTTP request
 * @param {Object} params - The function parameters
 * @param {string} params.method - The HTTP method ("GET", "POST", "PUT", "PATCH" or "DELETE")
 * @param {string} params.url - The API endpoint
 * @param {Object} [params.params={}] - The request parameters as key-value pairs
 * @param {function} params.onRequest - The callback to fire before sending the request
 * @param {function} params.onResponse - The callback to fire when a successful response is received
 * @param {function} params.onError - The callback to fire when an error occurs
 */
export async function apiHelper({method, url, params={}, onRequest, onResponse, onError}) {
    // Fire off the onRequest callback
    onRequest()

    const url_ = new URL(backendHost + url, window.location.origin)

    // For GET requests, params need to be included in the url
    // For POST etc, they're included in the request body
    if (method === 'GET') {
        url_.search = new URLSearchParams(params)
    }

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    })
    // Call the async fetch function
    try {
        const response = await fetch(url_, {
            method: method,
            body: method !== 'GET' ? JSON.stringify(params) : null,
            headers,
        })

        // Because we used await the next block of code will only execute when the fetch returns, 
        // even though other operation outside this function may proceed

        // If the contentType is JSON then the request was probably handled correctly by the backend
        // Both if the request succeeded or if the request failed in a manageable way
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
            const body = await response.json()
            // We check if the response succeeded and fire off the appropriate callback
            if (response.ok) {
                onResponse(body)
            }
            else {
                onError(body.message || body.msg || body.error || body.statusText)
            }
        }
        // For non-JSON content type we assume the backend errored out
        // In this case the error will be returned as plain text
        else {
            onError(await response.text())
        }
    }
    // If anything else goes wrong, return the raw javascript error
    catch (e) {
        onError(e.message)
    }
}
