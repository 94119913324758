export const FETCH_TIME_REQUEST = 'FETCH_TIME_REQUEST'
export const FETCH_TIME_RESPONSE = 'FETCH_TIME_RESPONSE'
export const FETCH_TIME_ERROR = 'FETCH_TIME_ERROR'

export const fetchTimeRequest = () => ({
    type: FETCH_TIME_REQUEST,
    message: "Fetching data",
})

export const fetchTimeResponse = (response) => ({
    type: FETCH_TIME_RESPONSE,
    response: response.datetime,
    message: response.message

})

export const fetchTimeError = (message) => ({
    type: FETCH_TIME_ERROR,
    message
})
