import React, { useEffect } from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'

import { statusToAlertType } from '../utils'

/**
 * Alert for indicating the state of an external data request
 */
export function DataManagementAlert({ status, message, clearAlerts = () => {console.log("Clear alerts")}}) {
    const alertType = statusToAlertType(status)
    useEffect(
        () => {
            if (message && alertType === 'success') {
                setTimeout(clearAlerts, 2000)
            }
        },
        [message, alertType, clearAlerts]
    )
    return alertType ? (
        <Alert color={alertType}>{message}</Alert>
    ) : (
        <Alert color="info invisible">Placeholder</Alert>
    )
}

DataManagementAlert.propTypes = {
    /** The status of the request: 'request', 'response' or 'error' */
    status: PropTypes.string.isRequired,
    /** The message to display */
    message: PropTypes.string.isRequired,
    /** The function to call to clear the alert after 2s */
    clearAlerts: PropTypes.func
}

export default DataManagementAlert
