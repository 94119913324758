import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Form, FormGroup, Label, Col, Input, Button, Card } from 'reactstrap'

import DataManagementAlert from './DataManagementAlert'

import { editOwnUser, editOwnUserError, clearAlerts } from '../actions'
import { changePassword, changePasswordError } from '../actions'
import { selectUser } from '../reducers/entities/users'

export function UserAccount() {
    const dispatch = useDispatch()
    const user_id = useSelector(state => state.auth.loggedInUserId)
    const user = useSelector(state => selectUser(state, user_id))
    const { status, message } = useSelector(state => state.dataManagement.account)
    const nameInput = useRef(null)
    const emailInput = useRef(null)
    const oldPasswordInput = useRef(null)
    const newPasswordInput = useRef(null)
    const confirmPasswordInput = useRef(null)
    const [editMode, setEditMode] = useState(false)

    const resetDetails = useCallback(() => {
        nameInput.current.value = user.user_name
        emailInput.current.value = user.user_email
    }, [user])

    useEffect(() => {
        resetDetails()
        setEditMode(false)
    }, [user, resetDetails])

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    function toggleEditMode(e) {
        e.preventDefault()
        setEditMode(val => !val)
        resetDetails()
        dispatch(clearAlerts())
    }

    function editOwnUserHandler(e) {
        e.preventDefault()
        const user_name = nameInput.current.value
        const user_email = emailInput.current.value
        if (user_name && user_email) {
            const data = {
                user_name,
                user_email,
            }
            dispatch(editOwnUser(data))
        } else {
            dispatch(editOwnUserError(
                'All compulsory fields indicated with * must be filled in'
            ))
        }
    }

    function changePasswordHandler(e) {
        e.preventDefault()
        const old_password = oldPasswordInput.current.value
        const new_password = newPasswordInput.current.value
        const confirm_password = confirmPasswordInput.current.value
        if (old_password && new_password && confirm_password) {
            if (new_password === confirm_password) {
                const data = {
                    old_password,
                    new_password,
                }
                dispatch(changePassword(data))
            } else {
                dispatch(changePasswordError('Password and confirmation must match'))
            }
        } else {
            dispatch(changePasswordError(
                'All compulsory fields indicated with * must be filled in'
            ))
        }
    }

    return (
        <>
            <h3>UserAccount</h3>
            
            <Card body className="mb-2">
                <Form onSubmit={editOwnUserHandler}>
                    <FormGroup row>
                        <Label for="name" md={3}>
                            Name *
                        </Label>
                        <Col md={6}>
                            <Input
                                name="user_name"
                                id="user_name"
                                innerRef={nameInput}
                                disabled={!editMode}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="user_email" md={3}>
                            Email *
                        </Label>
                        <Col md={6}>
                            <Input
                                type="email"
                                name="user_email"
                                id="user_email"
                                innerRef={emailInput}
                                disabled={!editMode}
                            />
                        </Col>
                    </FormGroup>
                    {editMode ? (
                        <FormGroup row>
                            <Col md={{ size: 3, offset: 3 }}>
                                <Button
                                    block
                                    color="primary"
                                    className="mb-2"
                                >
                                    Submit
                                </Button>
                            </Col>
                            <Col md={{ size: 3 }}>
                                <Button
                                    block
                                    onClick={toggleEditMode}
                                    className="mb-2"
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </FormGroup>
                    ) : (
                        <FormGroup row>
                            <Col md={{ size: 3, offset: 3 }}>
                                <Button
                                    block
                                    color="primary"
                                    onClick={toggleEditMode}
                                    className="mb-2"
                                >
                                    Edit Account
                                </Button>
                            </Col>
                        </FormGroup>
                    )}
                </Form>
            </Card>
            <Card body className="mb-2">
                <Form onSubmit={changePasswordHandler}>
                    <FormGroup row>
                        <Label for="old_password" md={3}>
                            Old Password *
                        </Label>
                        <Col md={6}>
                            <Input
                                type="password"
                                name="old_password"
                                id="old_password"
                                innerRef={oldPasswordInput}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="new_password" md={3}>
                            New Password *
                        </Label>
                        <Col md={6}>
                            <Input
                                type="password"
                                name="new_password"
                                id="new_password"
                                innerRef={newPasswordInput}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="confirm_password" md={3}>
                            Confirm Password *
                        </Label>
                        <Col md={6}>
                            <Input
                                type="password"
                                name="confirm_password"
                                id="confirm_password"
                                innerRef={confirmPasswordInput}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={{ size: 3, offset: 3 }}>
                            <Button
                                block
                                color="primary"
                            >
                                Change Password
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </Card>
            <DataManagementAlert status={status} message={message} />
        </>
    )
}

export default UserAccount
