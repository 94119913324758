import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { loginUser, loginUserError } from '../actions'

import DataManagementAlert from './DataManagementAlert'

export function Login() {
    const emailInput = useRef(null)
    const passwordInput = useRef(null)
    const { status, message } = useSelector(state => state.dataManagement.login)
    const history = useHistory()
    const dispatch = useDispatch()

    function onSubmitHandler(e) {
        e.preventDefault()
        const user_email = emailInput.current.value
        const user_password = passwordInput.current.value

        if (user_email && user_password) {
            const data = {
                user_email,
                user_password,
            }
            dispatch(loginUser(data))
        } else {
            dispatch(loginUserError('Login with email and password'))
        }
    }

    function viewDemo(e) {
        e.preventDefault()
        history.push('/demo')
    }

    return (
        <>
            <h3>Login</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="user_email" md={3}>
                        Email
                    </Label>
                    <Col md={6}>
                        <Input
                            innerRef={emailInput}
                            type="email"
                            name="user_email"
                            id="user_email"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_password" md={3}>
                        Password
                    </Label>
                    <Col md={6}>
                        <Input
                            innerRef={passwordInput}
                            type="password"
                            name="user_password"
                            id="user_password"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                            className="mb-2"
                        >
                            Login
                        </Button>
                    </Col>
                    <Col md={{ size: 2 }}>
                        <Button
                            block
                            className="mb-2"
                            type="button"
                            onClick={viewDemo}
                        >
                            View Demo
                        </Button>
                    </Col>
                    <Col md={{ size: 2 }}>
                        <Button
                            block
                            className="mb-2"
                            disabled
                        >
                            Register (coming soon)
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default Login
