import { combineReducers } from 'redux'

import users from './users'
import organisations from './organisations'
import dashboards from './dashboards'
import time from './time'

export default combineReducers({
    users,
    organisations,
    dashboards,
    time,
})
