import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'
import { createOrganisation, createOrganisationError, clearAlerts } from '../actions'
import DataManagementAlert from './DataManagementAlert'

export function AddOrganisation() {
    const dispatch = useDispatch()
    const nameInput = useRef(null)
    const { status, message } = useSelector(state => state.dataManagement.addOrganisation)

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    function onSubmitHandler(e) {
        e.preventDefault()
        const organisation_name = nameInput.current.value
        if (organisation_name) {
            const data = {
                organisation_name
            }
            dispatch(createOrganisation(data))
        }
        else {
            dispatch(createOrganisationError("All compulsory fields indicated with * must be filled in"))
        }
    }

    return (
        <>
            <h3>Add Organisation</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="organisation_name" md={3}>
                        Name*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisation_name"
                            id="organisation_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Create
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default AddOrganisation
