import { combineReducers } from 'redux'

import dataManagement from './dataManagement'
import entities from './entities'
import auth from './auth'

export default combineReducers({
    dataManagement,
    entities,
    auth
})
