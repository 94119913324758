import { combineReducers } from 'redux'

import {
    LOGIN_USER_REQUEST, LOGIN_USER_ERROR, LOGIN_USER_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return "request"
        case LOGIN_USER_ERROR:
            return "error"
        case LOGIN_USER_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
        case LOGIN_USER_ERROR:
        case LOGIN_USER_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
