import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { selectDashboards } from '../reducers/entities/dashboards'
import DashboardTable from './DashboardTable'

export function ListDashboards() {
    const history = useHistory()
    const dashboards = useSelector(selectDashboards)
    const [selectedDashboard, setSelectedDashboard] = useState(null)

    const onSelect = (dashboard_id) => {
        setSelectedDashboard(dashboard_id)
    }

    const onEdit = (e) => {
        e.preventDefault()
        history.push(`editDashboard/${selectedDashboard}`)
    }

    return (
        <>
            <h3>List Dashboards</h3>
            <DashboardTable dashboards={dashboards} onSelect={onSelect}/>
            <Button onClick={onEdit} disabled={selectedDashboard === null} color="primary">Edit</Button>
        </>
    )
}

export default ListDashboards
