import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'

export default function UserTable({ users, onSelect }) {
    const columns = [
        {
            dataField: "user_id",
            text: "Id",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "user_name",
            text: "Name",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "user_email",
            text: "Email",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "organisation_name",
            text: "Organisation",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "is_admin",
            text: "Admin",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "expiry_date",
            text: "Expiry Date",
            sort: true,
            filter: textFilter(),
        }
    ]

    const defaultSorted = [
        {
            dataField: "user_id",
            order: "asc",
        }
    ]

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            onSelect(row.user_id)
        }

    }

    return (
        <BootstrapTable
            bootstrap4
            bordered={ false }
            striped
            hover
            keyField="user_id"
            data={ users }
            columns={ columns }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            selectRow={ selectRow }
            pagination={ paginationFactory({ }) }
        />
    )
}