import { apiHelper } from '../utils'

export const FETCH_ORGANISATIONS_REQUEST = 'FETCH_ORGANISATIONS_REQUEST'
export const FETCH_ORGANISATIONS_RESPONSE = 'FETCH_ORGANISATIONS_RESPONSE'
export const FETCH_ORGANISATIONS_ERROR = 'FETCH_ORGANISATIONS_ERROR'
export const CREATE_ORGANISATION_REQUEST = 'CREATE_ORGANISATION_REQUEST'
export const CREATE_ORGANISATION_RESPONSE = 'CREATE_ORGANISATION_RESPONSE'
export const CREATE_ORGANISATION_ERROR = 'CREATE_ORGANISATION_ERROR'
export const EDIT_ORGANISATION_REQUEST = 'EDIT_ORGANISATION_REQUEST'
export const EDIT_ORGANISATION_RESPONSE = 'EDIT_ORGANISATION_RESPONSE'
export const EDIT_ORGANISATION_ERROR = 'EDIT_ORGANISATION_ERROR'

export const fetchOrganisations = () => {
    return async (dispatch) => {
        const url = `/organisations`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchOrganisationsRequest()),
            onResponse: (body) => dispatch(fetchOrganisationsResponse(body)),
            onError: (message) => dispatch(fetchOrganisationsError(message)),
        })
    }
}

export const fetchOrganisationsRequest = () => ({
    type: FETCH_ORGANISATIONS_REQUEST,
    message: "Attempting to fetch organisations details"
})

export const fetchOrganisationsResponse = (response) => ({
    type: FETCH_ORGANISATIONS_RESPONSE,
    response: response,
    message: "Fetched organisations"
})

export const fetchOrganisationsError = (message) => ({
    type: FETCH_ORGANISATIONS_ERROR,
    message
})

export const createOrganisation = (data) => {
    return async (dispatch) => {
        const url = `/organisations`
        await apiHelper({
            method: 'POST',
            url: url,
            params: data,
            onRequest: () => dispatch(createOrganisationRequest()),
            onResponse: (body) => dispatch(createOrganisationResponse(body)),
            onError: (message) => dispatch(createOrganisationError(message)),
        })
    }
}

export const createOrganisationRequest = () => ({
    type: CREATE_ORGANISATION_REQUEST,
    message: "Attempting to create organisation details"
})

export const createOrganisationResponse = (response) => ({
    type: CREATE_ORGANISATION_RESPONSE,
    response: response,
    message: "Created organisation"
})

export const createOrganisationError = (message) => ({
    type: CREATE_ORGANISATION_ERROR,
    message
})

export const editOrganisation = (organisation_id, data) => {
    return async (dispatch) => {
        const url = `/organisations/${organisation_id}`
        await apiHelper({
            method: 'PUT',
            url: url,
            params: data,
            onRequest: () => dispatch(editOrganisationRequest()),
            onResponse: (body) => dispatch(editOrganisationResponse(body)),
            onError: (message) => dispatch(editOrganisationError(message)),
        })
    }
}

export const editOrganisationRequest = () => ({
    type: EDIT_ORGANISATION_REQUEST,
    message: "Attempting to edit organisation details"
})

export const editOrganisationResponse = (response) => ({
    type: EDIT_ORGANISATION_RESPONSE,
    response: response,
    message: "Edited organisation"
})

export const editOrganisationError = (message) => ({
    type: EDIT_ORGANISATION_ERROR,
    message
})