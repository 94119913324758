import { apiHelper } from '../utils'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_RESPONSE = 'REGISTER_USER_RESPONSE'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const EDIT_OWN_USER_REQUEST = 'EDIT_OWN_USER_REQUEST'
export const EDIT_OWN_USER_RESPONSE = 'EDIT_OWN_USER_RESPONSE'
export const EDIT_OWN_USER_ERROR = 'EDIT_OWN_USER_ERROR'
export const CHANGE_OWN_PASSWORD_REQUEST = 'CHANGE_OWN_PASSWORD_REQUEST'
export const CHANGE_OWN_PASSWORD_RESPONSE = 'CHANGE_OWN_PASSWORD_RESPONSE'
export const CHANGE_OWN_PASSWORD_ERROR = 'CHANGE_OWN_PASSWORD_ERROR'
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_RESPONSE = 'FETCH_USERS_RESPONSE'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_RESPONSE = 'EDIT_USER_RESPONSE'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const registerUser = (data) => {
    return async (dispatch) => {
        const url = '/auth/users'
        await apiHelper({
            method: 'POST',
            url: url,
            params: data,
            onRequest: () => dispatch(registerUserRequest()),
            onResponse: (body) => dispatch(registerUserResponse(body)),
            onError: (message) => dispatch(registerUserError(message)),
        })
    }
}

export const registerUserRequest = () => ({
    type: REGISTER_USER_REQUEST,
    message: "Attempting to register user",
})

export const registerUserResponse = (response) => ({
    type: REGISTER_USER_RESPONSE,
    response: response,
    message: "Registered User"

})

export const registerUserError = (message) => ({
    type: REGISTER_USER_ERROR,
    message
})


export const loginUser = (data) => {
    return async (dispatch) => {
        const url = '/auth/login'
        await apiHelper({
            method: 'POST',
            url: url,
            params: data,
            onRequest: () => dispatch(loginUserRequest()),
            onResponse: (body) => dispatch(loginUserResponse(body)),
            onError: (message) => dispatch(loginUserError(message)),
        })
    }
}

export const logoutUser = () => {
    return async (dispatch) => {
        const url = '/auth/logout'
        await apiHelper({
            method: 'POST',
            url: url,
            params: {},
            onRequest: () => dispatch(logoutUserRequest()),
            onResponse: () => {},
            onError: () => {},
        })
    }
}

export const loginUserRequest = () => ({
    type: LOGIN_USER_REQUEST,
    message: "Attempting Login"
})

export const loginUserResponse = (response) => ({
    type: LOGIN_USER_RESPONSE,
    response: response,
    message: "Logged in"
})

export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    message
})

export const logoutUserRequest = () => ({
    type: LOGOUT_USER,
})

export const editOwnUser = (data) => {
    return async (dispatch) => {
        const url = `/auth/user`
        await apiHelper({
            method: 'PUT',
            url: url,
            params: data,
            onRequest: () => dispatch(editOwnUserRequest()),
            onResponse: (body) => dispatch(editOwnUserResponse(body)),
            onError: (message) => dispatch(editOwnUserError(message)),
        })
    }
}

export const editOwnUserRequest = () => ({
    type: EDIT_OWN_USER_REQUEST,
    message: "Attempting to edit user details"
})

export const editOwnUserResponse = (response) => ({
    type: EDIT_OWN_USER_RESPONSE,
    response: response,
    message: "Edited User"
})

export const editOwnUserError = (message) => ({
    type: EDIT_OWN_USER_ERROR,
    message
})

export const editUser = (user_id, data) => {
    return async (dispatch) => {
        const url = `/auth/users/${user_id}`
        await apiHelper({
            method: 'PUT',
            url: url,
            params: data,
            onRequest: () => dispatch(editUserRequest()),
            onResponse: (body) => dispatch(editUserResponse(body)),
            onError: (message) => dispatch(editUserError(message)),
        })
    }
}

export const editUserRequest = () => ({
    type: EDIT_USER_REQUEST,
    message: "Attempting to edit user details"
})

export const editUserResponse = (response) => ({
    type: EDIT_USER_RESPONSE,
    response: response,
    message: "Edited User"
})

export const editUserError = (message) => ({
    type: EDIT_USER_ERROR,
    message
})

export const fetchUsers = () => {
    return async (dispatch) => {
        const url = `/auth/users`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchUsersRequest()),
            onResponse: (body) => dispatch(fetchUsersResponse(body)),
            onError: (message) => dispatch(fetchUsersError(message)),
        })
    }
}

export const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST,
    message: "Attempting to fetch users details"
})

export const fetchUsersResponse = (response) => ({
    type: FETCH_USERS_RESPONSE,
    response: response,
    message: "Fetched Users"
})

export const fetchUsersError = (message) => ({
    type: FETCH_USERS_ERROR,
    message
})

export const changePassword = (data) => {
    return async (dispatch) => {
        const url = `/auth/user/password`
        await apiHelper({
            method: 'PATCH',
            url: url,
            params: data,
            onRequest: () => dispatch(changePasswordRequest()),
            onResponse: (body) => dispatch(changePasswordResponse(body)),
            onError: (message) => dispatch(changePasswordError(message)),
        })
    }
}

export const changePasswordRequest = () => ({
    type: CHANGE_OWN_PASSWORD_REQUEST,
    message: "Attempting to change user password"
})

export const changePasswordResponse = (response) => ({
    type: CHANGE_OWN_PASSWORD_RESPONSE,
    response: response,
    message: "Changed password"
})

export const changePasswordError = (message) => ({
    type: CHANGE_OWN_PASSWORD_ERROR,
    message
})

export const resetPassword = (user_id, data) => {
    return async (dispatch) => {
        const url = `/auth/users/${user_id}/password`
        await apiHelper({
            method: 'PATCH',
            url: url,
            params: data,
            onRequest: () => dispatch(resetPasswordRequest()),
            onResponse: (body) => dispatch(resetPasswordResponse(body)),
            onError: (message) => dispatch(resetPasswordError(message)),
        })
    }
}

export const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST,
    message: "Attempting to reset user password"
})

export const resetPasswordResponse = (response) => ({
    type: RESET_PASSWORD_RESPONSE,
    response: response,
    message: "Reset user password"
})

export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    message
})
