import { combineReducers } from 'redux'

import {
    FETCH_TIME_REQUEST, FETCH_TIME_ERROR, FETCH_TIME_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case FETCH_TIME_REQUEST:
            return "request"
        case FETCH_TIME_ERROR:
            return "error"
        case FETCH_TIME_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case FETCH_TIME_REQUEST:
        case FETCH_TIME_ERROR:
            return action.message
        case FETCH_TIME_RESPONSE:
            return "Done"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
