import { combineReducers } from 'redux'

import {
    EDIT_DASHBOARD_REQUEST, EDIT_DASHBOARD_ERROR, EDIT_DASHBOARD_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case EDIT_DASHBOARD_REQUEST:
            return "request"
        case EDIT_DASHBOARD_ERROR:
            return "error"
        case EDIT_DASHBOARD_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case EDIT_DASHBOARD_REQUEST:
        case EDIT_DASHBOARD_ERROR:
        case EDIT_DASHBOARD_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
