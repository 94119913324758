import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'

import DataManagementAlert from './DataManagementAlert'
import { selectUser } from '../reducers/entities/users'
import { selectOrganisations } from '../reducers/entities/organisations'
import { editUser, editUserError, resetPassword, resetPasswordError, clearAlerts } from '../actions'

export function EditUser() {
    const dispatch = useDispatch()
    const { user_id } = useParams()
    const user = useSelector(state => selectUser(state, user_id))
    const organisations = useSelector(selectOrganisations)
    const organisationInput = useRef(null)
    const nameInput = useRef(null)
    const emailInput = useRef(null)
    const passwordInput = useRef(null)
    const expiryDateInput = useRef(null)
    const adminInput = useRef(null)
    const { status, message } = useSelector(state => state.dataManagement.editUser)

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    useEffect(() => {
        nameInput.current.value = user.user_name
        emailInput.current.value = user.user_email
        organisationInput.current.value = user.organisation_id
        expiryDateInput.current.value = user.expiry_date
        adminInput.current.checked = user.is_admin
    }, [user])

    function onEditUserHandler(e) {
        e.preventDefault()
        const organisation_id = organisationInput.current.value
        const user_name = nameInput.current.value
        const user_email = emailInput.current.value
        const expiry_date = expiryDateInput.current.value
        const is_admin = adminInput.current.checked
        if ( user_name && user_email ) {
            const data = {
                user_name,
                user_email,
                organisation_id,
                expiry_date,
                is_admin,
            }
            dispatch(editUser(user_id, data))
        }
        else {
            dispatch(editUserError("All compulsory fields indicated with * must be filled in"))
        }
    }
    function onResetPasswordHandler(e) {
        e.preventDefault()
        const user_password = passwordInput.current.value
        const data = {
            user_password
        }
        if ( user_password ) {
            dispatch(resetPassword(user_id, data))
        }
        else {
            dispatch(resetPasswordError("All compulsory fields indicated with * must be filled in"))
        }
    }

    return (
        <>
            <h3>Edit User</h3>
            <Form onSubmit={onEditUserHandler}>
                <FormGroup row>
                    <Label for="user_id" md={3}>
                        Id
                    </Label>
                    <Col md={6}>
                        <Input
                            name="user_id"
                            id="user_id"
                            type="text"
                            disabled
                            value={user_id}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="organisation" md={3}>
                        Organisation
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisation"
                            id="organisation"
                            type="select"
                            innerRef={organisationInput}
                        >
                            { organisations.map(organisation => (
                                <option
                                    key={organisation.organisation_id}
                                    value={organisation.organisation_id}
                                >
                                    {organisation.organisation_name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_name" md={3}>
                        Name *
                    </Label>
                    <Col md={6}>
                        <Input
                            name="user_name"
                            id="user_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="user_email" md={3}>
                        Email *
                    </Label>
                    <Col md={6}>
                        <Input
                            type="email"
                            name="user_email"
                            id="user_email"
                            innerRef={emailInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="expiry_date" md={3}>
                        Expiry Date
                    </Label>
                    <Col md={6}>
                        <Input
                            type="date"
                            name="expiry_date"
                            id="expiry_date"
                            innerRef={expiryDateInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="is_admin" md={3}>
                        Admin
                    </Label>
                    <Col md={6}>
                        <Input
                            type="checkbox"
                            name="is_admin"
                            id="is_admin"
                            innerRef={adminInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Update
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <h3>Reset Password</h3>
            <Form onSubmit={onResetPasswordHandler}>
                <FormGroup row>
                    <Label for="user_password" md={3}>
                        Password *
                    </Label>
                    <Col md={6}>
                        <Input
                            type="password"
                            name="user_password"
                            id="user_password"
                            innerRef={passwordInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Reset
                        </Button>
                    </Col>
                </FormGroup>

            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default EditUser
