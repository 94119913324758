import React from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Topbar from './Topbar'
import Home from './Home'
import Login from './Login'
import ManageUsers from './ManageUsers'
import UserAccount from './UserAccount'
import ManageOrganisations from './ManageOrganisations'
import ManageDashboards from './ManageDashboards'
import Dashboard from './Dashboard'
import Demo from './Demo'

function ProtectedRoute({ isAllowed, ...props }) {
    return isAllowed ? <Route {...props} /> : <Redirect to="/login" />
}

function AntiProtectedRoute({ isAllowed, ...props }) {
    return isAllowed ? <Redirect to="/" /> : <Route {...props} />
}

export function App() {
    const { loggedIn, loggedInAdmin } = useSelector(state => state.auth)
    return (
        <>
            <Topbar />
            <Container fluid={true}>
                <Row>
                    <Col>
                        <main>
                            <Card body className="mt-2">
                                <Switch>
                                    <ProtectedRoute
                                        isAllowed={loggedIn}
                                        exact path="/"
                                        component={Home} 
                                    />
                                    <AntiProtectedRoute
                                        isAllowed={loggedIn}
                                        path="/login"
                                        component={Login}
                                    />
                                    <ProtectedRoute
                                        isAllowed={loggedIn}
                                        path="/account"
                                        component={UserAccount}
                                    />
                                    <ProtectedRoute
                                        isAllowed={loggedInAdmin}
                                        path="/admin/users"
                                        component={ManageUsers}
                                    />
                                    <ProtectedRoute
                                        isAllowed={loggedInAdmin}
                                        path="/admin/organisations"
                                        component={ManageOrganisations}
                                    />
                                    <ProtectedRoute
                                        isAllowed={loggedInAdmin}
                                        path="/admin/dashboards"
                                        component={ManageDashboards}
                                    />
                                    <ProtectedRoute
                                        isAllowed={loggedIn}
                                        path="/dashboard/:dashboard_id"
                                        component={Dashboard}
                                    />
                                    <Route path="/demo" component={Demo}/>
                                    <Route render={() => <Redirect to="/"/>}/>
                                </Switch>
                            </Card>
                        </main>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withRouter(App)
