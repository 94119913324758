import { combineReducers } from 'redux'

import { LOGIN_USER_RESPONSE, LOGOUT_USER } from '../../actions'

const loggedIn = (state = false, action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
            return true
        case LOGOUT_USER:
            return false
        default:
            return state
    }
}

const loggedInAdmin = (state = false, action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
            const { is_admin } = action.response
            return is_admin
        case LOGOUT_USER:
            return false
        default:
            return state
    }
}

const loggedInUserId = (state = '', action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
            const { user_id } = action.response
            return user_id
        case LOGOUT_USER:
            return ''
        default:
            return state
    }
}

const loggedInOrganisationId = (state = '', action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
            const { organisation_id } = action.response
            return organisation_id
        case LOGOUT_USER:
            return ''
        default:
            return state
    }
}

const loginToken = (state = '', action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
            const { access_token } = action.response
            return access_token
        case LOGOUT_USER:
            return ''
        default:
            return state
    }
}

export default combineReducers({
    loggedIn,
    loggedInAdmin,
    loggedInUserId,
    loggedInOrganisationId,
    loginToken,
})
