import { combineReducers } from 'redux'

import {
    EDIT_ORGANISATION_REQUEST, EDIT_ORGANISATION_ERROR, EDIT_ORGANISATION_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case EDIT_ORGANISATION_REQUEST:
            return "request"
        case EDIT_ORGANISATION_ERROR:
            return "error"
        case EDIT_ORGANISATION_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case EDIT_ORGANISATION_REQUEST:
        case EDIT_ORGANISATION_ERROR:
        case EDIT_ORGANISATION_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
