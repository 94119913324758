import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'

export default function DashboardTable({ dashboards, onSelect }) {
    const columns = [
        {
            dataField: "dashboard_id",
            text: "Id",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "dashboard_name",
            text: "Name",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "dashboard_description",
            text: "Description",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "is_public",
            text: "Public",
            sort: true,
            filter: textFilter(),
        }
    ]

    const defaultSorted = [
        {
            dataField: "dashboard_id",
            order: "asc",
        }
    ]

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            onSelect(row.dashboard_id)
        }

    }

    return (
        <BootstrapTable
            bootstrap4
            bordered={ false }
            striped
            hover
            keyField="dashboard_id"
            data={ dashboards }
            columns={ columns }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            selectRow={ selectRow }
            pagination={ paginationFactory({ }) }
        />
    )
}