import { combineReducers } from 'redux'

import {
    EDIT_USER_REQUEST, EDIT_USER_ERROR, EDIT_USER_RESPONSE,
    RESET_PASSWORD_REQUEST, RESET_PASSWORD_ERROR, RESET_PASSWORD_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case EDIT_USER_REQUEST:
        case RESET_PASSWORD_REQUEST:
            return "request"
        case EDIT_USER_ERROR:
        case RESET_PASSWORD_ERROR:
            return "error"
        case EDIT_USER_RESPONSE:
        case RESET_PASSWORD_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case EDIT_USER_REQUEST:
        case EDIT_USER_ERROR:
        case EDIT_USER_RESPONSE:
        case RESET_PASSWORD_REQUEST:
        case RESET_PASSWORD_ERROR:
        case RESET_PASSWORD_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
