import { combineReducers } from 'redux'

import login from './login'
import register from './register'
import editUser from './editUser'
import addDashboard from './addDashboard'
import editDashboard from './editDashboard'
import addOrganisation from './addOrganisation'
import editOrganisation from './editOrganisation'
import account from './account'
import fetchDemoRedux from './fetchDemoRedux'

export default combineReducers({
    login,
    register,
    editUser,
    addDashboard,
    editDashboard,
    addOrganisation,
    editOrganisation,
    account,
    fetchDemoRedux
})
