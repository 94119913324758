import { apiHelper } from '../utils'

export const FETCH_DASHBOARDS_REQUEST = 'FETCH_DASHBOARDS_REQUEST'
export const FETCH_DASHBOARDS_RESPONSE = 'FETCH_DASHBOARDS_RESPONSE'
export const FETCH_DASHBOARDS_ERROR = 'FETCH_DASHBOARDS_ERROR'
export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST'
export const FETCH_DASHBOARD_RESPONSE = 'FETCH_DASHBOARD_RESPONSE'
export const FETCH_DASHBOARD_ERROR = 'FETCH_DASHBOARD_ERROR'
export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST'
export const CREATE_DASHBOARD_RESPONSE = 'CREATE_DASHBOARD_RESPONSE'
export const CREATE_DASHBOARD_ERROR = 'CREATE_DASHBOARD_ERROR'
export const EDIT_DASHBOARD_REQUEST = 'EDIT_DASHBOARD_REQUEST'
export const EDIT_DASHBOARD_RESPONSE = 'EDIT_DASHBOARD_RESPONSE'
export const EDIT_DASHBOARD_ERROR = 'EDIT_DASHBOARD_ERROR'
export const LINK_DASHBOARD_REQUEST = 'LINK_DASHBOARD_REQUEST'
export const LINK_DASHBOARD_RESPONSE = 'LINK_DASHBOARD_RESPONSE'
export const LINK_DASHBOARD_ERROR = 'LINK_DASHBOARD_ERROR'

export const fetchDashboards = () => {
    return async (dispatch) => {
        const url = `/dashboards`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchDashboardsRequest()),
            onResponse: (body) => dispatch(fetchDashboardsResponse(body)),
            onError: (message) => dispatch(fetchDashboardsError(message)),
        })
    }
}

export const fetchDashboardsRequest = () => ({
    type: FETCH_DASHBOARDS_REQUEST,
    message: "Attempting to fetch dashboards details"
})

export const fetchDashboardsResponse = (response) => ({
    type: FETCH_DASHBOARDS_RESPONSE,
    response: response,
    message: "Fetched dashboards"
})

export const fetchDashboardsError = (message) => ({
    type: FETCH_DASHBOARDS_ERROR,
    message
})

export const fetchDashboard = (dashboardId) => {
    return async (dispatch) => {
        const url = `/dashboards/${dashboardId}`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchDashboardRequest()),
            onResponse: (body) => dispatch(fetchDashboardResponse(body)),
            onError: (message) => dispatch(fetchDashboardError(message)),
        })
    }
}
export const fetchDashboardRequest = () => ({
    type: FETCH_DASHBOARD_REQUEST,
    message: "Attempting to fetch dashboard details"
})

export const fetchDashboardResponse = (response) => ({
    type: FETCH_DASHBOARD_RESPONSE,
    response: response,
    message: "Fetched dashboard"
})

export const fetchDashboardError = (message) => ({
    type: FETCH_DASHBOARD_ERROR,
    message
})

export const fetchPublicDashboards = () => {
    return async (dispatch) => {
        const url = `/dashboards/public`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchDashboardsRequest()),
            onResponse: (body) => dispatch(fetchDashboardsResponse(body)),
            onError: (message) => dispatch(fetchDashboardsError(message)),
        })
    }
}

export const fetchOrganisationDashboards = (organisationId) => {
    return async (dispatch) => {
        const url = `/dashboards/organisations/${organisationId}`
        await apiHelper({
            method: 'GET',
            url: url,
            onRequest: () => dispatch(fetchDashboardsRequest()),
            onResponse: (body) => dispatch(fetchDashboardsResponse(body)),
            onError: (message) => dispatch(fetchDashboardsError(message)),
        })
    }
}

export const createDashboard = (data, organisation_ids) => {
    return async (dispatch) => {
        const url = `/dashboards`
        await apiHelper({
            method: 'POST',
            url: url,
            params: data,
            onRequest: () => dispatch(createDashboardRequest()),
            onResponse: (body) => {
                dispatch(createDashboardResponse(body))
                const { dashboard_id } = body
                const link_data = {
                    organisation_ids
                }
                dispatch(linkDashboard(dashboard_id, link_data))
            },
            onError: (message) => dispatch(createDashboardError(message)),
        })
    }
}

export const createDashboardRequest = () => ({
    type: CREATE_DASHBOARD_REQUEST,
    message: "Attempting to create dashboard details"
})

export const createDashboardResponse = (response) => ({
    type: CREATE_DASHBOARD_RESPONSE,
    response: response,
    message: "Created dashboard"
})

export const createDashboardError = (message) => ({
    type: CREATE_DASHBOARD_ERROR,
    message
})

export const editDashboard = (dashboard_id, data, organisation_ids) => {
    return async (dispatch) => {
        const url = `/dashboards/${dashboard_id}`
        await apiHelper({
            method: 'PUT',
            url: url,
            params: data,
            onRequest: () => dispatch(editDashboardRequest()),
            onResponse: (body) => {
                dispatch(editDashboardResponse(body))
                const { dashboard_id } = body
                const link_data = {
                    organisation_ids
                }
                dispatch(linkDashboard(dashboard_id, link_data))
            },
            onError: (message) => dispatch(editDashboardError(message)),
        })
    }
}

export const editDashboardRequest = () => ({
    type: EDIT_DASHBOARD_REQUEST,
    message: "Attempting to edit dashboard details"
})

export const editDashboardResponse = (response) => ({
    type: EDIT_DASHBOARD_RESPONSE,
    response: response,
    message: "Edited dashboard"
})

export const editDashboardError = (message) => ({
    type: EDIT_DASHBOARD_ERROR,
    message
})

export const linkDashboard = (dashboard_id, data) => {
    return async (dispatch) => {
        const url = `/dashboards/${dashboard_id}/link_to_organisations`
        await apiHelper({
            method: 'POST',
            url: url,
            params: data,
            onRequest: () => dispatch(linkDashboardRequest()),
            onResponse: (body) => dispatch(linkDashboardResponse(body)),
            onError: (message) => dispatch(linkDashboardError(message)),
        })
    }
}

export const linkDashboardRequest = () => ({
    type: LINK_DASHBOARD_REQUEST,
    message: "Attempting to link dashboard to organisations"
})

export const linkDashboardResponse = (response) => ({
    type: LINK_DASHBOARD_RESPONSE,
    response: response,
    message: "Linked dashboards"
})

export const linkDashboardError = (message) => ({
    type: LINK_DASHBOARD_ERROR,
    message
})