import {
    FETCH_TIME_RESPONSE,
} from '../../actions'

const time = (state="12:00:00", action) => {
    switch (action.type) {
        case FETCH_TIME_RESPONSE:
            const datetime = new Date(action.response)
            return datetime.toLocaleTimeString()
        default:
            return state
    }
}
export default time
