import { map, fromPairs, sortBy} from 'lodash'
import {
    FETCH_DASHBOARD_RESPONSE,
    FETCH_DASHBOARDS_RESPONSE,
    CREATE_DASHBOARD_RESPONSE,
    EDIT_DASHBOARD_RESPONSE,
    LINK_DASHBOARD_RESPONSE,
    LOGOUT_USER,
} from '../../actions'

const dashboards = (state={}, action) => {
    switch (action.type) {
        case CREATE_DASHBOARD_RESPONSE:
        case LINK_DASHBOARD_RESPONSE:
        case EDIT_DASHBOARD_RESPONSE:
        case FETCH_DASHBOARD_RESPONSE:
            const { dashboard_id } = action.response
            return {
                ...state,
                [dashboard_id]: action.response
            }
        case FETCH_DASHBOARDS_RESPONSE:
            return {
                ...state,
                ...fromPairs(map(action.response, (dashboard) => ([dashboard.dashboard_id, dashboard])))
            }
        case LOGOUT_USER:
            return {}
        default:
            return state
    }
}

export default dashboards

export const selectDashboards = (state) => {
    const { dashboards } = state.entities
    return sortBy(map(dashboards, dashboard => dashboard), ["dashboard_id"])
}

export const selectPublicDashboards = (state) => {
    const dashboards = selectDashboards(state)
    return dashboards.filter(dashboard => dashboard.is_public)
}

export const selectOrganisationDashboards = (state, organisation_id) => {
    const dashboards = selectDashboards(state)
    return dashboards.filter(dashboard => dashboard.organisations.includes(organisation_id))
}

export const selectDashboard = (state, id) => {
    const dashboard = state.entities.dashboards[id]
    return dashboard
}