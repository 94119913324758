import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'
import { clearAlerts, createDashboard, createDashboardError } from '../actions'
import { selectOrganisations } from '../reducers/entities/organisations'
import DataManagementAlert from './DataManagementAlert'

export function AddDashboard() {
    const dispatch = useDispatch()
    const nameInput = useRef(null)
    const descriptionInput = useRef(null)
    const urlInput = useRef(null)
    const is_publicInput = useRef(null)
    const organisationsInput = useRef(null)
    const organisations = useSelector(selectOrganisations)
    const { status, message } = useSelector(state => state.dataManagement.addDashboard)

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    function onSubmitHandler(e) {
        e.preventDefault()
        const dashboard_name = nameInput.current.value
        const dashboard_description = descriptionInput.current.value
        const dashboard_url = urlInput.current.value
        const is_public = is_publicInput.current.checked
        const organisation_ids = Array.from(organisationsInput.current.selectedOptions, option => option.value)
        if (dashboard_name && dashboard_url) {
            const data = {
                dashboard_name,
                dashboard_description,
                dashboard_url,
                is_public
            }
            dispatch(createDashboard(data, organisation_ids))
        }
        else {
            dispatch(createDashboardError("All compulsory fields indicated with * must be filled in"))
        }
    }

    return (
        <>
            <h3>Add Dashboard</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="dashboard_name" md={3}>
                        Name*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_name"
                            id="dashboard_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dashboard_description" md={3}>
                        Description
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_description"
                            id="dashboard_description"
                            innerRef={descriptionInput}
                            type="textarea"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dashboard_url" md={3}>
                        URL*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_url"
                            id="dashboard_url"
                            innerRef={urlInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="organisations" md={3}>
                        Linked Organisations
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisations"
                            id="organisations"
                            type="select"
                            multiple
                            innerRef={organisationsInput}
                        >
                            { organisations.map(organisation => (
                                <option
                                    key={organisation.organisation_id}
                                    value={organisation.organisation_id}
                                >
                                    {organisation.organisation_name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="is_public" md={3}>
                        Public
                    </Label>
                    <Col md={6}>
                        <Input
                            type="checkbox"
                            name="is_public"
                            id="is_public"
                            default={true}
                            innerRef={is_publicInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Create
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default AddDashboard
