import React from 'react'

export function Demo() {
    const name = "Eighty20 National Segmentation Demo Dashboard"
    const url = "https://app.powerbi.com/view?r=eyJrIjoiMjcxNTdkNzktMGZhOC00MDllLTk0YTUtYWQ0NzBjYjM5YzFiIiwidCI6IjZlMDQxZjZmLWQ2OGEtNDJlMy05MzFhLTZmM2MyOTFmMGZiZiIsImMiOjh9"
    return (
        <div>
            { url &&
                <iframe
                    title={ name }
                    width="100%"
                    height="850"
                    src={ url }
                    style={{ border: 0 }}
                    allowFullScreen={true}
                />
            }
        </div>
    )
}

export default Demo
