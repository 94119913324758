import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { selectUsers } from '../reducers/entities/users'
import { selectOrganisations } from '../reducers/entities/organisations'
import UserTable from './UserTable'

export function ListUsers() {
    const history = useHistory()
    const users = useSelector(selectUsers)
    const organisations = useSelector(selectOrganisations)
    const [selectedUser, setSelectedUser] = useState(null)

    const data = users.map(user => ({
        ...user,
        organisation_name: organisations.length > 0 ? organisations.find(organisation => organisation.organisation_id === user.organisation_id).organisation_name : user.organisation_id
    }))

    const onSelect = (user_id) => {
        setSelectedUser(user_id)
    }

    const onEdit = () => {
        history.push(`editUser/${selectedUser}`)
    }

    return (
        <>
            <h3>List Users</h3>
            <UserTable users={data} onSelect={onSelect}/>
            <Button onClick={onEdit} disabled={selectedUser === null} color="primary">Edit</Button>
        </>
    )
}

export default ListUsers
