import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'

export function OrganisationTable({ organisations, onSelect }) {
    const columns = [
        {
            dataField: "organisation_id",
            text: "Id",
            sort: true,
            filter: textFilter(),
        }, {
            dataField: "organisation_name",
            text: "Name",
            sort: true,
            filter: textFilter(),
        }
    ]

    const defaultSorted = [
        {
            dataField: "organisation_id",
            order: "asc",
        }
    ]

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            onSelect(row.organisation_id)
        }

    }

    return (
        <BootstrapTable
            bootstrap4
            bordered={ false }
            striped
            hover
            keyField="organisation_id"
            data={ organisations }
            columns={ columns }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            selectRow={ selectRow }
            pagination={ paginationFactory({ }) }
        />
    )
}

export default OrganisationTable
