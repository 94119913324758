import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'
import { forEach, map } from 'lodash'
import { selectDashboard } from '../reducers/entities/dashboards'
import { selectOrganisations } from '../reducers/entities/organisations'
import { editDashboard, editDashboardError, clearAlerts } from '../actions'
import DataManagementAlert from './DataManagementAlert'

export function EditDashboard() {
    const dispatch = useDispatch()
    const { dashboard_id } = useParams()
    const dashboard = useSelector(state => selectDashboard(state, dashboard_id))
    const organisations = useSelector(selectOrganisations)
    const nameInput = useRef(null)
    const descriptionInput = useRef(null)
    const urlInput = useRef(null)
    const is_publicInput = useRef(null)
    const organisationsInput = useRef(null)
    const { status, message } = useSelector(state => state.dataManagement.editDashboard)

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    useEffect(() => {
        nameInput.current.value = dashboard.dashboard_name
        descriptionInput.current.value = dashboard.dashboard_description
        urlInput.current.value = dashboard.dashboard_url
        is_publicInput.current.checked = dashboard.is_public
        const dashboard_organisations = map(dashboard.organisations, organisation_id => String(organisation_id))
        forEach(organisationsInput.current.options, option => {
            if (dashboard_organisations.includes(option.value)) {
                option.selected = true
            }
            else {
                option.selected = false
            }
        })
    }, [dashboard, organisations])

    function onSubmitHandler(e) {
        e.preventDefault()
        const dashboard_name = nameInput.current.value
        const dashboard_description = descriptionInput.current.value
        const dashboard_url = urlInput.current.value
        const is_public = is_publicInput.current.checked
        const organisation_ids = Array.from(organisationsInput.current.selectedOptions, option => option.value)
        if (dashboard_name && dashboard_url) {
            const data = {
                dashboard_name,
                dashboard_description,
                dashboard_url,
                is_public
            }
            dispatch(editDashboard(dashboard_id, data, organisation_ids))
        }
        else{
            dispatch(editDashboardError("All compulsory fields indicated with * must be filled in"))
        }
    }

    return (
        <>
            <h3>Edit Dashboard</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="dashboard_id" md={3}>
                        Id
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_id"
                            id="dashboard_id"
                            disabled
                            value={dashboard_id}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dashboard_name" md={3}>
                        Name*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_name"
                            id="dashboard_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dashboard_description" md={3}>
                        Description
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_description"
                            id="dashboard_description"
                            innerRef={descriptionInput}
                            type="textarea"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dashboard_url" md={3}>
                        URL*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="dashboard_url"
                            id="dashboard_url"
                            innerRef={urlInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="organisations" md={3}>
                        Linked Organisations
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisations"
                            id="organisations"
                            type="select"
                            multiple
                            innerRef={organisationsInput}
                        >
                            { organisations.map(organisation => (
                                <option
                                    key={organisation.organisation_id}
                                    value={organisation.organisation_id}
                                >
                                    {organisation.organisation_name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="is_public" md={3}>
                        Public
                    </Label>
                    <Col md={6}>
                        <Input
                            type="checkbox"
                            name="is_public"
                            id="is_public"
                            default={true}
                            innerRef={is_publicInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Update
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default EditDashboard
