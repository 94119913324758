export * from './auth'
export * from './organisations'
export * from './dashboards'
export * from './time'

export const CLEAR_ALERTS = 'CLEAR_ALERTS'

export const clearAlerts = () => ({
    type: CLEAR_ALERTS,
})
