import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, Link, useRouteMatch, Redirect } from 'react-router-dom'
import {
    Nav,
    NavItem,
    NavLink,
    Col, Row
} from 'reactstrap'
import { fetchUsers, fetchOrganisations } from '../actions'
import AddUser from './AddUser'
import EditUser from './EditUser'
import ListUsers from './ListUsers'

export function ManageUsers() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUsers())
        dispatch(fetchOrganisations())
    }, [dispatch])

    const match = useRouteMatch()
    return (
        <>
            <Row>
                <Col md={2}>
                    <h3>Manage Users</h3>
                    <Nav pills vertical>
                        <NavItem active>
                            <NavLink tag={Link} to={`${match.path}/listUsers`}>
                                List Users
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={`${match.path}/addUser`}>
                                Add User
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={`${match.path}/editUser`}>
                                Edit User
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md={10}>
                    <Switch>
                        <Route path={`${match.path}/listUsers`} component={ListUsers}/>
                        <Route path={`${match.path}/addUser`} component={AddUser}/>
                        <Route path={`${match.path}/editUser/:user_id`} component={EditUser}/>
                        <Redirect from={`${match.path}`} to={`${match.path}/listUsers`}/>
                    </Switch>
                </Col>
            </Row>
        </>
    )
}

export default ManageUsers
