import { combineReducers } from 'redux'

import {
    REGISTER_USER_REQUEST, REGISTER_USER_ERROR, REGISTER_USER_RESPONSE,
    CLEAR_ALERTS
} from '../../actions'

const status = (state="", action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
            return "request"
        case REGISTER_USER_ERROR:
            return "error"
        case REGISTER_USER_RESPONSE:
            return "response"
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

const message = (state="", action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
        case REGISTER_USER_ERROR:
        case REGISTER_USER_RESPONSE:
            return action.message
        case CLEAR_ALERTS:
            return ""
        default:
            return state
    }
}

export default combineReducers({
    status,
    message
})
