import { map, fromPairs, sortBy} from 'lodash'
import {
    LOGIN_USER_RESPONSE,
    LOGOUT_USER,
    EDIT_OWN_USER_RESPONSE,
    EDIT_USER_RESPONSE,
    FETCH_USERS_RESPONSE,
    REGISTER_USER_RESPONSE,
} from '../../actions'

const users = (state={}, action) => {
    switch (action.type) {
        case LOGIN_USER_RESPONSE:
        case EDIT_OWN_USER_RESPONSE:
        case EDIT_USER_RESPONSE:
        case REGISTER_USER_RESPONSE:
            const { user_id } = action.response
            return {
                ...state,
                [user_id]: action.response
            }
        case FETCH_USERS_RESPONSE:
            return {
                ...state,
                ...fromPairs(map(action.response, (user) => ([user.user_id, user])))
            }
        case LOGOUT_USER:
            return {}
        default:
            return state
    }
}

export default users

export const selectUsers = (state) => {
    const { users } = state.entities
    return sortBy(map(users, user => user), ["user_id"])
}

export const selectUser = (state, id) => {
    const user = state.entities.users[id]
    return user
}

export const selectLoggedInUser = (state) => {
    const loggedInUserId = state.auth.loggedInUserId
    const user = selectUser(state, loggedInUserId)
    return user
}