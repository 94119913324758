import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Button,
} from 'reactstrap'
import { editOrganisation, clearAlerts, editOrganisationError } from '../actions'
import { selectOrganisation } from '../reducers/entities/organisations'
import DataManagementAlert from './DataManagementAlert'

export function EditOrganisation() {
    const dispatch = useDispatch()
    const { organisation_id } = useParams()
    const organisation = useSelector(state => selectOrganisation(state, organisation_id))
    const nameInput = useRef(null)
    const { status, message } = useSelector(state => state.dataManagement.editOrganisation)

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch])

    useEffect(() => {
        nameInput.current.value = organisation.organisation_name
    }, [organisation])

    function onSubmitHandler(e) {
        e.preventDefault()
        const organisation_name = nameInput.current.value
        if (organisation_name) {
            const data = {
                organisation_name
            }
            dispatch(editOrganisation(organisation_id, data))
        }
        else {
            dispatch(editOrganisationError("All compulsory fields indicated with * must be filled in"))
        }
    }

    return (
        <>
            <h3>Edit Organisation</h3>
            <Form onSubmit={onSubmitHandler}>
                <FormGroup row>
                    <Label for="organisation_id" md={3}>
                        Id
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisation_id"
                            id="organisation_id"
                            type="text"
                            disabled
                            value={organisation_id}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="organisation_name" md={3}>
                        Name*
                    </Label>
                    <Col md={6}>
                        <Input
                            name="organisation_name"
                            id="organisation_name"
                            innerRef={nameInput}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{ size: 2, offset: 3 }}>
                        <Button
                            block
                            color="primary"
                        >
                            Update
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            <DataManagementAlert status={status} message={message}/>
        </>
    )
}

export default EditOrganisation
