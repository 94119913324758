import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, Link, useRouteMatch, Redirect } from 'react-router-dom'
import {
    Nav,
    NavItem,
    NavLink,
    Col, Row
} from 'reactstrap'
import { fetchDashboards, fetchOrganisations } from '../actions'
import AddDashboard from './AddDashboard'
import EditDashboard from './EditDashboard'
import ListDashboards from './ListDashboards'

export function ManageDashboards() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDashboards())
        dispatch(fetchOrganisations())
    }, [dispatch])
    const match = useRouteMatch()
    return (
        <>
            <Row>
                <Col md={2}>
                    <h3>Manage Dashboards</h3>
                    <Nav pills vertical>
                        <NavItem active>
                            <NavLink tag={Link} to={`${match.path}/listDashboards`}>
                                List Dashboards
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink tag={Link} to={`${match.path}/addDashboard`}>
                                Add Dashboard
                            </NavLink>
                        </NavItem>
                        <NavItem active>
                            <NavLink tag={Link} to={`${match.path}/editDashboard`}>
                                Edit Dashboard
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md={10}>
                    <Switch>
                        <Route path={`${match.path}/listDashboards`} component={ListDashboards}/>
                        <Route path={`${match.path}/addDashboard`} component={AddDashboard}/>
                        <Route path={`${match.path}/editDashboard/:dashboard_id`} component={EditDashboard}/>
                        <Redirect from={`${match.path}`} to={`${match.path}/listDashboards`}/>
                    </Switch>
                </Col>
            </Row>
        </>
    )
}

export default ManageDashboards
